<template>
  <router-view />
</template>
<script>
export default {
  name: "HomePatient",
  data() {
    return {};
  },
  async created() {
    const token = await this.$authState.accessToken();
    const url = document.config.webSocketUrl;
    await this.$store.dispatch("websocket/connect", { url, token });
  }
};
</script>
